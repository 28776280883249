.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav-Bar {
  height: 30px;
  width: 100%;
  display: inline-block;
  background-color: rgb(124, 150, 181);
}

.Nav-Right {
  float: right;
  padding-right: 5px;
  padding-top: 5px;
  justify-self: center;
  justify-content: center;
}

.Nav-Left {
  float: left;
  padding: 6px 5px;
  justify-self: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 50px;
}

p.Nav-Right {
  margin-top: auto;
}

.Login-Div {
  margin-top: 30vh;
  height: 33vh;
  width: 33vw;
  margin-right: auto;
  margin-left: auto;
  min-width: 250px;
  min-height: 300px;
  background-color: #b8c8d9;
  padding: 10px;
  border-radius: 10px;
}

.Home-Div {
  margin-top: 25vh;
  height: 33vh;
  width: 33vw;
  margin-right: auto;
  margin-left: auto;
  background-color: #b8c8d9;
  padding: 10px;
  border-radius: 10px;
}

.Login-Form {
  display: flexbox;
  margin-top:5%;
  justify-content: center;
}

.pd-3 {
  padding: 3px;
  margin: 5px;
}

.formik-error {
  color: red;
}

.show-element {
  display: block;
  pointer-events: auto;
  transition: 0.5s all;
}

.hide-element {
  display: none;
  pointer-events: none;
}

.contact-form {
  max-height: 70vh;
  overflow-y: auto;
}

.registration-form {
  max-height: 90vh;
  overflow-y: auto;
}

.new-project-form {
  max-height: 70vh;
  overflow-y: auto;
}

.leaflet-div {
  width: 100%;
  min-height: 400px;
  height: 33vh;
}

.error{
  color: red;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  justify-items: left;
  text-align: left;
}

.project-description {
  max-height: 400px;
  overflow-y: auto;
}

.consult-detail {
  height: 95vh;
}

.file-drop-zone {
  border: dashed;
  border-color: rgb(53, 100, 255);
  border-width: 4px;
  height: 115px;
}

.file-drop-zone-active {
  border: dashed;
  border-width: 2px;
  border-color: rgb(137, 255, 249);
  height: 150px;
  margin: 2px;
}

.file-div {
  border: solid;
  border-width: 1px;
  padding: 2px;
  margin: 2px;
}